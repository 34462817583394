<template lang="pug">
  figure
    .relative.bg-gray-000(:class="isBleed && 'pb-ar-' + aspectRatio.replace(':', 'x')")
      //- aspect-ratio sizing for portrait cell:
      //- template(v-if="aspectRatio === 'half-portrait-cell'")
        .pb-ar-4x3.mb-48
        .pb-ar-4x3
      //- media slot should always be absolute !
      slot(name="media")
    //- caption
    media-caption(v-bind="$attrs")
      slot(name="caption")
</template>

<script>
import MediaCaption from './Media__Caption'
export default {
  name: 'Media',
  props: {
    aspectRatio: { type: String, default: 'undef' },
    isBleed: { type: Boolean, default: true }
  },
  components: { MediaCaption },
  inheritAttrs: false
}
</script>

<style>
</style>
