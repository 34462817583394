<template lang="pug">
  .absolute.inset-0.w-full.h-full
    //- image
    template(v-if="slide.type === 'image'")
      .absolute.inset-0.w-full.h-full.flex
        blur-image-frame(v-if="imgL && slide.media.url", :dims="slide.media.dimensions")
          resp-img(:bg="true", :lngth="imgL", :useHeight="!$store.getters.isPortrait", bgClass="bg-contain bg-center bg-no-repeat", :src="slide.media.url", :alt="slide.media.alt")
    //- vimeo
    template(v-else-if="slide.type === 'vimeo'")
      vimeo-player(ref="vimeo", :vimeoURL="slide.media.url", bgSize="contain", :clip="slide.media.clip", :controls="slide.media.controls", @firstplay="onVimeoLoaded", :progress="active", :visible="active", v-if="active")
</template>

<script>
import RespImg from '@/components/RespImg'
import BlurImageFrame from '@/components/BlurImageFrame'
import VimeoPlayer from '@/components/VimeoPlayer'
export default {
  name: 'ProjectViewer__Slide',
  props: ['slide', 'active', 'imgL'],
  methods: {
    onVimeoLoaded () {
      if (!this.active) this.$refs.vimeo.pause()
    }
  },
  watch: {
    active (actv) {
      if (!this.$refs.vimeo) return
      if (actv) return this.$refs.vimeo.play()
      return this.$refs.vimeo.pause()
    }
  },
  components: { BlurImageFrame, RespImg, VimeoPlayer }
}
</script>

<style>
</style>
