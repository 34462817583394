<template lang="pug">
  section.px-12.pb-px.pt-72.md-pt-144.md-px-24.lg-px-36
    .colc-grid(ref="grid")
      .colc-grid-col.colc-grid-col--1.px-12.md-px-24.lg-px-36.float-left
      .colc-grid-col.colc-grid-col--2.px-12.md-px-24.lg-px-36.float-left.hidden.md-block
      .colc-grid-col.colc-grid-col--3.px-12.md-px-24.lg-px-36.float-left.hidden.lg-block
      .colc-grid-col.colc-grid-col--4.px-12.md-px-24.lg-px-36.float-left.hidden.sm-block
      //- items
      router-link.colc-grid-item.block.mb-72(v-for="(prj, i) in projects", :to="{name: 'project', params: {uid: prj.uid}}")
        article(v-if="prj.data")
          header.text-16
            h3.italic.font-medium {{ txt(prj.data.title) }}
            h5 {{ txt(prj.data.location) }}, {{ prjYear(prj) }}
          figure.my-24(v-if="prj.data._index_image")
            .relative(:class="prj.data._ar_class")
              resp-img(:bg="true", :lazy="i > 6", :lngth="imgL", :src="prj.data._index_image.url", :alt="prj.data._index_image.alt", v-if="imgL")
          p.text-15 {{ trunc(txt(prj.data.summary)) }}
</template>

<script>
import Vue from 'vue'
import RespImg from '@/components/RespImg'
import Colcade from 'colcade'
import _truncate from 'lodash/truncate'
import { mapState } from 'vuex'
import { prjYear } from '@/utils'
export default {
  name: 'ProjectsByImage',
  props: ['fetch'],
  data () {
    return {
      colc: null,
      txt: this.$prismic.richTextAsPlain,
      trunc: txt => _truncate(txt, { length: 70, separator: /,? +/ }),
      prjYear
    }
  },
  computed: {
    ...mapState(['winW', 'projects']),
    imgL () {
      const w = this.winW
      const _ = Vue.config.bkpts
      return w >= _.lg ? parseInt(w * 0.25) // 4 col
        : w >= _.md ? parseInt(w * 0.33) // 3 col
          : w >= _.sm ? parseInt(w * 0.5) // 2 col
            : w
    }
  },
  methods: {
    initGrid () {
      this.colc = new Colcade(this.$refs.grid, {
        columns: '.colc-grid-col',
        items: '.colc-grid-item'
      })
    },
    async getPrjs () {
      if (!this.fetch) return true
      return this.$store.dispatch('getProjects')
    }
  },
  async mounted () {
    await this.getPrjs()
    this.initGrid()
  },
  components: { RespImg }
}
</script>

<style>
@import '../style/_settings.css';

/* Colcade widths need to be attached to the class it seems (:/) */
@media (--bkpt-sm) {
  .colc-grid-col { width: 50%; }
}
@media (--bkpt-md) {
  .colc-grid-col { width: calc(100% / 3); }
}
@media (--bkpt-lg) {
  .colc-grid-col { width: 25%; }
}
</style>
