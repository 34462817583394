<template lang="pug">
  media(v-bind="$attrs", :count="[active, items.length]", :aspectRatio="aspectRatio")
    //- media
    //- TODO cursors...
    .absolute.inset-0.w-full.h-full.lg-cursor-expand(slot="media", ref="frame", @click="openViewer(activeItm.image.url)")
      transition-group(name="fade")
        resp-img(v-for="(item, i) in items", v-if="imgL", :key="img(item).url || i", :bg="true", :lazy="i > 0", :lngth="imgL", :useHeight="isPortrait", :src="img(item).url", :alt="img(item).alt || $prismic.richTextAsPlain(item.caption)", v-show="active === i")
      nav.absolute.z-10.inset-0.flex.justify-between(v-if="items.length > 1")
        button.hidden.lg-block.lg-w-1x3.cursor-left-arrow(@click.stop="next(-1)", aria-label="Previous Image")
        button.w-full.lg-w-1x3.cursor-right-arrow(@click.stop="next(1)", aria-label="Next Image")
    //- caption
    span(slot="caption", v-if="activeItm")
      prismic-rich-text.inline.children-inline(v-if="activeItm.caption", :field="activeItm.caption")
</template>

<script>
import Media from './Media'
import RespImg from '@/components/RespImg'
import { mapGetters } from 'vuex'
export default {
  name: 'Imageset',
  props: ['items', 'aspectRatio'],
  data () {
    return {
      active: 0,
      imgL: 0
    }
  },
  computed: {
    ...mapGetters(['winIs']),
    activeItm () {
      return this.items && this.items[this.active]
    },
    isPortrait () {
      return this.aspectRatio === '4x5'
    }
  },
  methods: {
    next (incr = 1) {
      const actv = this.active
      const total = this.items.length
      this.active = actv + incr === total ? 0 : actv + incr < 0 ? total - 1 : actv + incr
    },
    setImgL () {
      this.imgL = this.isPortrait ? this.$refs.frame.offsetHeight : this.$refs.frame.offsetWidth
    },
    openViewer (url) {
      if (this.winIs('lg')) return this.$store.commit('OPEN_VIEWER', url)
    },
    img (item) {
      const img = item.image
      if (!img || !img.url) return false
      const ar = this.aspectRatio.replace('x', ':')
      return img[ar] || img
    }
  },
  mounted () {
    setTimeout(() => this.setImgL(), 800) // after page anim
  },
  components: { Media, RespImg },
  inheritAttrs: false
}
</script>

<style>
</style>
