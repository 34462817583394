<template lang="pug">
  //- outer / margins
  .fixed.inset-0.bg-white.z-40.flex.pt-9vh.pb-12vh.px-24(@mousemove="onMouse", :class="{'cursor-left-arrow': hover === 'left', 'cursor-right-arrow': hover === 'right'}", @click="onClick")
    //- inset frame
    .relative.z-20.w-full.overflow-hidden(ref="frame")
      //- slides
      viewer-slide.project-viewer__slide(v-for="(slide, i) in slides", :key="i", :class="{'project-viewer__slide--active': active === i}", :slide="slide", :active="active === i", :imgL="imgL", :index="i")
    //- caption
    .absolute.z-20.w-full.bottom-0.left-0.flex.items-end.p-24.text-16.tracking-40.bg-white
      .w-1x8
      figcaption.flex-1.text-center
        prismic-rich-text.inline.children-inline.child-bold-text-gray-500(v-if="activeSlide", :field="activeSlide.caption")
      //- count
      .w-1x8.text-right.font-numbers-normal {{active + 1}}&mdash;{{slides.length}}
    //- close btn
    .absolute.z-30.top-0.right-0.p-18
      button.p-12(@click.stop="$store.commit('CLOSE_VIEWER')", aria-label="Close Viewer")
        svg-x.w-16.h-16
</template>

<script>
import ViewerSlide from './ProjectViewer__Slide'
import svgX from '@/components/Icons/SVG-X'
import { mapState } from 'vuex'
import { next } from '@/utils'
import _throttle from 'lodash/throttle'
export default {
  name: 'ProjectViewer',
  props: ['slides', 'start'],
  data () {
    return {
      active: -1,
      imgL: 0,
      hover: null
    }
  },
  computed: {
    ...mapState(['winH', 'winW', 'viewerAt']),
    activeSlide () {
      return this.slides[this.active]
    }
  },
  methods: {
    next (dir = 1) {
      this.active = next(this.active, dir, this.slides.length)
    },
    setStart () {
      this.active = this.slides.findIndex(slide => slide.id === this.viewerAt)
    },
    onMouse: _throttle(function (e) {
      const isVimeoFrame = e.path.find(el => el.classList && el.classList.value.includes('vimeo-player__frame'))
      const isLeft = e.screenX <= this.winW * 0.5 // assuming overlay is full window
      this.hover = isVimeoFrame ? 'vimeo' : isLeft ? 'left' : 'right'
    }, 100),
    onClick () {
      switch (this.hover) {
        case 'left': return this.next(-1)
        case 'right': return this.next(1)
      }
    }
  },
  created () {
    this.setStart()
  },
  mounted () {
    this.imgL = parseInt(this.winH * 0.85)
  },
  watch: {
    viewerAt () {
      this.setStart()
    }
  },
  components: { ViewerSlide, svgX }
}
</script>

<style>
.project-viewer__slide{
  /* hiding */
  transition: opacity 200ms, visibility 0s 200ms;
  opacity:0;
  visibility: hidden;
  /* showing */
  &.project-viewer__slide--active{
    transition: opacity 700ms 100ms, visibility 0s 200ms;
    opacity:1;
    visibility: visible;
  }

}
</style>
