<template lang="pug">
  figcaption.flex.text-11.md-text-14.items-start.child-bold-text-gray-500(:class="{'min-h-48': !flush, 'pt-12 pb-24': !noPadding}")
    .flex-1 <slot></slot>
    .w-56.text-right.font-numbers-normal
      span(v-if="count && count[1] > 1") {{count[0] + 1}}&mdash;{{count[1]}}
</template>

<script>
export default {
  name: 'Media__Caption',
  props: ['flush', 'count', 'noPadding']
}
</script>

<style>
</style>
