<template lang="pug">
  footer.-mt-px.md-mb-72.px-24.pb-24.md-pb-2.font-mono.text-gray-500.text-10.md-text-12(v-if="canRender")
    //- slice body
    section.border-t.border-b.py-12.lg-flex.justify-center
      //- (layout: multipl. slices = 2col)
      template(v-if="slices.length > 1")
        .w-grid-24.-ml-24.px-12.flex.flex-wrap.lg-flex-no-wrap.justify-center
          //- slices...
          template(v-for="(slice, i) in slices", v-if="i < 2")
            //- (separator line)
            .w-full.my-12.md-hidden.px-12(v-if="i > 0")
              .border-t

            //- (...footer section)
            template(v-if="slice.slice_type === 'footer_section'")
              //- header
              h6.w-1x2.md-w-3x18.lg-w-2x18.pr-12.border-r.md-border-none.px-12 <b>{{ $prismic.richTextAsPlain(slice.primary.heading) }}</b>
              //- text
              prismic-rich-text.w-1x2.md-w-6x18.lg-w-5x18.pl-12.px-12.md-pb-52.child-links-hover-black(:field="slice.primary.text", :class="{'md-border-r': !i}")

      //- (layout: one slice = 1col)
      template(v-else)
        //- slices...
        .lg-w-8x18.px-12(v-for="(slice) in slices")
          .w-grid-24.-ml-24.px-12.flex
            //- (...footer section)
            template(v-if="slice.slice_type === 'footer_section'")
              h6.w-1x2.md-w-2x8.pr-12.md-px-12.border-r.md-border-none.flex-shrink-0
                | <b>{{ $prismic.richTextAsPlain(slice.primary.heading) }}</b>
              prismic-rich-text.w-1x2.pl-12.md-w-7x8.md-px-12.md-pb-52.child-links-hover-black(:field="slice.primary.text")

    //- (products)
    observer.project-footer__products.py-12.md-flex.border-b(v-if="productsFull")
      //- heading
      .lg-w-5x18.lg-flex-shink-0
      h5.mb-12.md-px-12.md-w-2x8.lg-w-2x18.flex-shrink-0(:class="{'md-w-2x8': slices.length === 1, 'md-w-3x18 lg-w-2x18': slices.length > 1}") <b>Art Editions</b>
      //- scroll body
      observer.flex-1.relative.overflow-hidden.project-footer__products__body.project-footer__products__body--hidden(ref="relatedProductsBody", :threshold="0.9", @visible="onProductsVisible")
        .w-full.overflow-x-scroll.scrollbars-hidden.relative
          .whitespace-no-wrap.pl-12
            //- products...
            router-link.inline-block.border.mr-12.border.rounded.hover-bg-black.hover-text-white.hover-border-blackff.group(v-for="product in productsFull", :to="{name: 'product', params: { uid: product.uid }}")
              .flex.items-center
                resp-img.h-112.flex.flex-shrink-0(:lazy="true", :useHeight="true", :src="product.data.images[0].primary.image.url", :alt="product.data.images[0].primary.image.alt")
                h6.py-5.px-8.whitespace-normal.text-center(style="width:18em")
                  | {{ $prismic.richTextAsPlain(product.data.title) }}
                  template(v-if="product.data.year")
                   |, {{ product.data.year }}
                  span.hidden.group-hover-block View Edition &rarr;

        //- gradients
        .absolute.top-0.left-0.h-full.w-12.z-10.pointer-events-none(style="background:linear-gradient(to right, white, rgba(255,255,255,0))")
        .absolute.top-0.right-0.h-full.w-12.z-10.pointer-events-none(style="background:linear-gradient(to left, white, rgba(255,255,255,0))")

    //- (footnotes)
    section.lg-hidden.py-12.font-mono.text-8.border-b(v-if="footnotes.length")
      .relative(style="columns:2; column-gap:2.4rem")
        h6 <b>Footnotes</b>
        //- footnotes...
        .children-inline.mt-em(v-for="item in footnotes")
          span {{item.number}}.&nbsp;
          prismic-rich-text.inline.children-inline(:field="item.footnote")
        .absolute.w-1x2.h-full.top-0.left-0.border-r.z-0.pointer-events-none
</template>

<script>
import RespImg from '@/components/RespImg'
import Observer from '@/components/IntersectionObserver'
export default {
  name: 'ProjectFooter',
  props: ['slices', 'footnotes', 'products'],
  components: { RespImg, Observer },
  data () {
    return {
      productsFull: null
    }
  },
  computed: {
    canRender () {
      return this.slices.length || this.footnotes.length
    }
  },
  methods: {
    async getProducts () {
      try {
        const ids = this.products.map(row => row.product.id)
        this.productsFull = (await this.$prismic.client.getByIDs(ids)).results
      } catch (e) {
        console.error(e)
      }
    },
    onProductsVisible (e) {
      this.$refs.relatedProductsBody.$el.classList.remove('project-footer__products__body--hidden')
    }
  },
  mounted () {
    this.getProducts()
  }
}
</script>

<style>
.project-footer__products__body {
  transition: all 600ms;
}
.project-footer__products__body--hidden{
  transform:  translateX(40px);
  opacity: 0;
}
</style>
