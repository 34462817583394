<template lang="pug">
  .prj-quote.w-full
    //- hero
    template(v-if="slice.primary.format === 'hero'")
      .my-48.md-mt-60(:class="isMedia(next) ? 'md-mb-72' : 'md-mb-60'")
        .sm-w-15x18.lg-w-13x18.mx-auto.px-12
          blockquote.text-16.md-text-21.font-medium(v-html="html(slice.primary.text)")
      footnote.md-mt-12(:slice="slice", v-bind="$attrs")
    //- default: in text
    template(v-else)
      div
        footnote(:slice="slice", v-bind="$attrs")
        .sm-w-12x18.lg-w-10x18.px-12.mx-auto.text-13.md-text-16.mb-em.md-mt-12(:class="isMedia(next) ? 'md-mb-72' : 'md-mb-12'")
          blockquote.leading-wide.pl-24.md-w-quote-7col.md-ml-auto.md-pl-0.italic.tracking-40.children-mt-em(v-html="html(slice.primary.text)")
</template>

<script>
import Footnote from './Footnote'
import { superScriptRichText, isMedia } from '@/utils'
export default {
  name: 'QuoteSlice',
  props: ['slice', 'next', 'prev'],
  data () {
    return {
      html: superScriptRichText,
      isMedia
    }
  },
  components: { Footnote },
  inheritAttrs: false
}
</script>

<style>
</style>
