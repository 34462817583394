<template lang="pug">
  .scroll-pt.w-full.mb-48(v-if="id", :id="id", :class="isMedia(next) ? 'md-mb-72' : 'md-mb-60'")
</template>

<script>
import { idy, isMedia } from '@/utils'
export default {
  name: 'ScrollPoint',
  props: ['slice', 'next'],
  data () {
    return {
      isMedia
    }
  },
  computed: {
    id () {
      return this.slice && this.slice.primary && idy(this.slice.primary.label)
    }
  },
  inheritAttrs: false
}
</script>
