<template lang="pug">
  article#prj-mural.scroll-pt.bg-white(v-if="doc")
    //- header: split
    project-header(:doc="doc")
    //- summary
    section
      .px-12.my-72.md-flex.justify-end
        .px-12.mb-48.sm-w-12x18.lg-w-8x18.md-mb-0
          prismic-rich-text.text-13.md-text-16.tracking-20.leading-wide.mb-em.children-mt-em(:field="doc.summary")
          prismic-rich-text.text-13.md-text-16.tracking-20.leading-wide.text-gray-500(:field="doc.byline")
        aside.sm-w-3x18.lg-w-5x18.px-12.text-gray-500.child-links-underline.font-mono.text-10.md-text-12
          <b>Share</b><br>
          .leading-loose.child-links-underline
            | <a :href="`mailto:?body=${shareUrl}`" target="_blank" rel="noopener">Email</a>,<br>
            | <a :href="`https://facebook.com/sharer.php?href=${shareUrl}`" target="_blank" rel="noopener">Facebook</a>,<br>
            | <a :href="`https://twitter.com/share?url=${shareUrl}`" target="_blank" rel="noopener">Twitter</a>
      //- gray details, bordered
      .px-24.my-48.md-mb-0.md-mt-72.font-mono.text-gray-500.text-10.md-text-12
        .border-t.border-b.py-12.border-gray-200
          .w-grid-24.-ml-24.px-12.flex.md-block
            //- (product details header)
            .w-1x2.px-12.border-r.md-hidden
              h5 <b>Project Details</b>
            .w-1x2.sm-w-12x18.lg-w-8x18.md-flex.mx-auto.min-h-112
              .flex-1.px-12
                h6 <b>Project</b>
                small.block {{ txt(doc.title )}}
              .flex-1.px-12.md-border-l.md-border-r.border-gray-200
                h6 <b>Location</b>
                small.block {{ txt(doc.location) }}
              .flex-1.px-12
                h6 <b>Commissioner</b>
                small.block {{ txt(doc.commissioner) }}
    section#prj-body.scroll-pt
      project-body(:slices="doc.body", :footnotes="doc.footnotes")
    project-footer(:slices="doc.footer", :footnotes="doc.footnotes", :products="doc.related_products")
    //- prj index
    observer.project__index.h-75vh.blur-media.pointer-events-none(:threshold="0.9", @visible="toIndex = true", @hidden="toIndex = false", :class="{'overflow-hidden': !leaving}")
      projects-by-image.pt-144(:fetch="true")
    //- viewer
    transition(name="fade", v-on:before-enter="blurViewer = true", v-on:after-enter="blurViewer = false")
      project-viewer(:slides="mediaSlides", v-if="viewerAt", v-show="viewer", :class="{'blur-media': blurViewer}")
</template>

<script>
import store from '@/store'
import ProjectHeader from '@/components/Project/ProjectHeader'
import ProjectBody from '@/components/Project/ProjectBody'
import ProjectFooter from '@/components/Project/ProjectFooter'
import ProjectViewer from '@/components/Project/Viewer/ProjectViewer'
import ProjectsByImage from '@/components/ProjectsByImage'
import Observer from '@/components/IntersectionObserver'
import { mapState } from 'vuex'
import { getNav } from '@/utils'
export default {
  name: 'Project',
  props: ['uid', 'entering'],
  data () {
    return {
      txt: this.$prismic.richTextAsPlain,
      toIndex: false,
      afterScroll: null,
      leaving: false,
      blurViewer: true
    }
  },
  computed: {
    ...mapState(['viewer', 'viewerAt', 'projects']),
    doc () {
      const doc = this.projects.find(doc => doc.uid === this.uid)
      return doc && doc.data
    },
    mediaSlides () {
      const media = []
      if (!this.doc) return media
      const collect = body => body && body.forEach(slice => {
        const type = slice.slice_type
        // images
        if (type.includes('image')) {
          slice.items.forEach(itm => itm.image && media.push({
            type: 'image',
            id: itm.image.url,
            media: itm.image,
            caption: itm.caption
          }))
        }
        // vimeos
        if (type === 'vimeo') {
          const data = slice.primary
          if (!data.vimeo_url) return
          media.push({
            type: 'vimeo',
            id: data.vimeo_url,
            media: {
              url: data.vimeo_url,
              clip: [data.clip_start, data.clip_end],
              controls: data.viewer_settings.includes('playable')
            },
            caption: data.caption
          })
        }
      })
      collect(this.doc.header)
      collect(this.doc.body)
      return media
    },
    shareUrl () {
      return process.env.VUE_APP_DOMAIN + this.$route.path
    }
  },
  methods: {
    onScroll () {
      clearTimeout(this.afterScroll)
      this.afterScroll = setTimeout(() => {
        if (!this.toIndex) return
        this.leaving = true
        this.$nextTick(() => this.$emit('onEndTo', 'projects'))
      }, 1000)
    }
  },
  watch: {
    entering (enter) {
      // after enter jump to top
      return !enter && window.scroll(0, 0)
    },
    blurViewer (blur) {
      this.$store.commit('SET_BLUR', blur)
    }
  },
  mounted () {
    this.$store.state.bus.$on('newScrollY', this.onScroll)
  },
  beforeRouteEnter: (to, from, next) => getProject(to, from, next),
  beforeRouteUpdate: (to, from, next) => getProject(to, from, next),
  destroyed () {
    this.$store.commit('RESET_VIEWER')
  },
  components: { ProjectHeader, ProjectBody, ProjectFooter, ProjectViewer, ProjectsByImage, Observer }
}

const getProject = (to, from, next) => {
  store.commit('PROGRESS_START')
  store.dispatch('getProjects')
  store.dispatch('getProject', to.params.uid)
    .then(doc => {
      if (!doc) {
        // not found
        store.commit('PROGRESS_FAIL')
        return next({ name: 'notfound' })
      }
      store.commit('PROGRESS_FINISH')
      const nav = doc.data && doc.data.scroll_nav_enabled !== 'hidden' && getNav(doc.data.body)
      store.commit('SET_NAV', nav)
      next()
    })
    .catch(e => {
      console.error(e)
      next({ name: 'error' })
    })
}
</script>
