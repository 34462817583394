<template lang="pug">
  .prj-rich-text.w-full(v-if="slice", :class="margins")
    //- footnote (right aside)
    footnote(:slice="slice", v-bind="$attrs")
    //- main text
    .prj-rich-text__main.sm-w-12x18.lg-w-10x18.px-12.mx-auto.children-mt-em.text-13.md-text-16.tracking-20.leading-wide(v-html="html(slice.primary.text)")
</template>

<script>
import Footnote from './Footnote'
import { superScriptRichText, isMedia } from '@/utils'
export default {
  name: 'TextSlice',
  props: ['slice', 'index', 'next'],
  data () {
    return {
      html: superScriptRichText,
      isMedia
    }
  },
  computed: {
    margins () {
      return [
        !this.index ? 'mt-48 md-mt-60' : 'md-mt-12',
        isMedia(this.next) ? 'mb-48 md-mb-72' : 'mb-em md-mb-12'
      ]
    }
  },
  components: { Footnote },
  inheritAttrs: false
}
</script>

<style>
.prj-rich-text {
  &:last-child .prj-rich-text__main > *:last-child:after{
    content:'';
    display:inline-block;
    margin-left:.2em;
    width:10px; height:10px;
    transform:scale(.95,.95);
    border-radius: 100px;
    background: currentColor;
  }
}
</style>
