<template lang="pug">
  .w-full.my-48.md-mt-48(:class="isMedia(next) ? 'md-mb-72' : 'md-mb-60'")
    prismic-rich-text.sm-w-12x18.lg-w-10x18.mx-auto.px-12.lg-pr-36.text-18.md-text-23.font-medium(:field="slice.primary.heading")
</template>

<script>
import { isMedia } from '@/utils'
export default {
  name: 'Header',
  props: ['slice', 'next'],
  data () {
    return {
      isMedia
    }
  },
  inheritAttrs: false
}
</script>

<style>
</style>
