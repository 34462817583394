<template lang="pug">
  div
    slot
</template>

<script>
export default {
  name: 'IntersectionObserver',
  props: {
    threshold: { type: Number, default: 0.5 }
  },
  methods: {
    observe () {
      if (!window.IntersectionObserver) return this.$emit('visible')
      const callback = entries => {
        return entries[0] && entries[0].isIntersecting ? this.$emit('visible') : this.$emit('hidden')
      }
      const observer = new IntersectionObserver(callback, { threshold: this.threshold })
      observer.observe(this.$el)
    }
  },
  mounted () {
    this.observe()
  }
}
</script>

<style>
</style>
