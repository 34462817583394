<template lang="pug">
  .hidden.lg-block.absolute.left-0.w-full.px-12.pointer-events-none(v-if="notes")
    .ml-13x18.w-3x18.px-12.font-mono.text-gray-500.tracking-40.mb-em.pointer-events-auto(v-for="note in notes")
      | {{note.number}}.&nbsp;
      prismic-rich-text.inline.children-inline(:field="note.footnote")
</template>

<script>
import _get from 'lodash/get'
export default {
  name: 'Footnote',
  props: ['slice', 'footnotes'],
  computed: {
    numbers () {
      const nmbs = _get(this.slice, 'primary.insert_footnotes')
      return nmbs && nmbs.split(',').map(n => parseInt(n))
    },
    notes () {
      if (!this.footnotes) return null
      return this.numbers && this.numbers.map(n => this.footnotes.find(note => note.number === n))
    }
  },
  inheritAttrs: false
}
</script>

<style>
</style>
