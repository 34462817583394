<template lang="pug">
  header
    //- (text | image)
    template(v-if="doc.header_layout.includes('text | images')")
      .pt-72.md-pt-144.px-12.lg-px-prjbody.md-flex.flex-wrap
        .min-h-158.md-min-h-0.md-w-1x2.px-12.md-border-r.flex.items-center.justify-center
          .text-20.md-text-24.text-center
            prismic-rich-text.italic.font-medium(:field="doc.title")
            h4(v-html="subtitle(doc.location, doc.mural_completion_date)")
        .md-w-1x2.px-12.clearfix
          imageset(:items="slice0.items", aspectRatio="4x5", :flush="true")
      //- bottom border
      .hidden.md-block.px-24.w-full
        .pb-12.border-b

    //- (full)
    template(v-else)
      .pt-72.-mb-24.px-12
        .min-h-158.md-min-h-300.flex.items-center.justify-center
          .text-20.md-text-24.text-center.py-48.md-py-112
            prismic-rich-text.italic.font-medium(:field="doc.title")
            h4(v-html="subtitle(doc.location, doc.mural_completion_date)")
        .px-12.clearfix.lg-px-prjbody
          imageset(:items="slice0.items", aspectRatio="3x2")
</template>

<script>
import Imageset from '@/components/Project/Imageset'
import _get from 'lodash/get'
import { prjYear } from '@/utils'
export default {
  name: 'ProjectHeader',
  props: ['doc'],
  data () {
    return {
      prjYear
    }
  },
  computed: {
    slice0 () {
      return _get(this.doc, 'header[0]')
    }
  },
  methods: {
    subtitle (location, date) {
      const year = date && date.split('-')[0]
      let sub = this.$prismic.richTextAsPlain(location)
      sub += year ? ', <br class="md-hidden">' + year : ''
      return sub
    }
  },
  components: { Imageset }
}
</script>

<style>
</style>
