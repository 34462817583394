<template lang="pug">
  .prj-body.px-12.md-py-24.mb-48.md-mb-72.lg-px-prjbody.flex.flex-wrap(v-if="slices.length")
    component(v-for="(slice, i) in slices", v-if="show(slice)", :is="'slice-' + slice.slice_type", :slice="slice", :prev="slices[i - 1]", :index="i", :next="slices[i + 1]", v-bind="$attrs")
</template>

<script>
import Header from './Slice--Header'
import RichText from './Slice--Text'
import Quote from './Slice--Quote'
import MediaSlice from './Slice--Media'
import ScrollPoint from './Slice--ScrollPoint'
export default {
  name: 'SliceBody',
  props: ['slices'],
  methods: {
    show (slice) {
      return this.$options.components['slice-' + slice.slice_type]
    }
  },
  components: {
    'slice-heading': Header,
    'slice-text': RichText,
    'slice-quote': Quote,
    'slice-image_folio': MediaSlice,
    'slice-vimeo': MediaSlice,
    'slice-scroll_point': ScrollPoint
  },
  inheritAttrs: false
}
</script>

<style>
</style>
