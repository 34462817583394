<template lang="pug">
  media(v-bind="$attrs")
    //- lazy-component(slot="media")
    observer(slot="media", :threshold="0.15", @visible="play", @hidden="pause")
      vimeo-player.cursor-expand(ref="vimeo", v-bind="$attrs", @click.native="$emit('click')")
    prismic-rich-text.inline.children-inline.child-bold-text-gray-500(slot="caption", :field="caption")
</template>

<script>
import Media from './Media'
import Observer from '@/components/IntersectionObserver'
import VimeoPlayer from '@/components/VimeoPlayer'
import { mapState } from 'vuex'
export default {
  name: 'VimeoClip',
  props: ['caption'],
  computed: {
    ...mapState(['viewer'])
  },
  methods: {
    play () {
      return this.$refs.vimeo && this.$refs.vimeo.play()
    },
    pause () {
      return this.$refs.vimeo && this.$refs.vimeo.pause()
    }
  },
  watch: {
    viewer (val) {
      if (val) return this.pause()
      return this.play()
    }
  },
  components: { Media, VimeoPlayer, Observer }
}
</script>

<style>
</style>
