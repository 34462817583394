<template lang="pug">
  .prj-media.w-full(:class="css")
    //- inner (text-width sized)
    div(:class="{'sm-w-12x18 lg-w-10x18 mx-auto px-12': frmt[0] === 'text-width'}")
      //- image folio
      template(v-if="slice.slice_type === 'image_folio'")
        imageset(:items="slice.items", :aspectRatio="aspectRatio", :truncate="truncCaption")
      //- vimeo
      template(v-else-if="slice.slice_type === 'vimeo'")
        vimeo-clip(:vimeoURL="slice.primary.vimeo_url", :clip="[slice.primary.clip_start, slice.primary.clip_end]", :aspectRatio="aspectRatio", :bgSize="vimeoIsBleed && 'cover'", @click="openViewer(slice.primary.vimeo_url)", :caption="slice.primary.caption", :truncate="truncCaption")
</template>

<script>
import Imageset from './Imageset'
import VimeoClip from './VimeoClip'
import { mapGetters } from 'vuex'
export default {
  name: 'Slice--Media',
  props: ['slice', 'next', 'prev'],
  computed: {
    ...mapGetters(['winIs']),
    format () {
      // '[width] : [landscape/portrait] : [float]'
      return (this.slice && this.slice.primary.format) || ''
    },
    frmt () {
      return this.format.split(' : ')
    },
    aspectRatio () {
      const frmt = this.format
      const is3x2 = frmt.includes('full') || frmt.includes('third')
      const isNativeWidthVimeo = this.slice.slice_type === 'vimeo' && !this.vimeoIsBleed
      return isNativeWidthVimeo ? 'none'
        : frmt.includes('portrait') ? '4x5'
          : is3x2 ? '3x2'
            : '4x3'
    },
    css () {
      const frmt = this.frmt
      const nxt = this.next || {}
      // media in text-body
      // if (frmt[0] === 'text-width') return 'md-float-left w-full'
      return [
        // frmt[2] && frmt[2].includes('right') ? 'md-float-right' : 'md-float-left',
        {
          // 'w-full': frmt[0] === 'text-width',
          'px-12': frmt[0] === 'full',
          // 'md-float-left w-full': frmt[0] === 'text-width',
          // 'w-full': frmt[0] === 'full',
          'px-12 md-w-1x2': frmt[0] === 'half',
          'px-12 md-w-1x3': frmt[0] === 'third',
          // MARGINS
          // header/quote:hero (deduction against 1/2 caption pb >> md-72)
          'md--mb-12': nxt.slice_type === 'header' || (nxt.slice_type === 'quote' && nxt.primary.format === 'hero'),
          // text/quote (addition w/ caption height >> md-72)
          'mb-24 md-mb-36': nxt.slice_type === 'text' || (nxt.slice_type === 'quote' && nxt.primary.format === 'default')
        }
      ]
    },
    truncCaption () {
      return !['full', 'text-width', 'third'].includes(this.frmt[0])
    },
    vimeoIsBleed () {
      return !['full', 'text-width'].includes(this.frmt[0])
    }
  },
  methods: {
    openViewer (url) {
      if (this.winIs('lg')) return this.$store.commit('OPEN_VIEWER', url)
    }
  },
  components: { Imageset, VimeoClip },
  inheritAttrs: false
}
</script>

<style>
</style>
